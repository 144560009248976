var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "language-selection d-flex" },
    [
      _c("base-single-selection", {
        attrs: {
          itemKey: "id",
          itemName: "name",
          itemSelectedDisplay: "name",
          options: _vm.languages,
          placeholder: "Select language",
          displayClass: "text-uppercase",
          right: "",
        },
        scopedSlots: _vm._u([
          {
            key: "pre-button-content",
            fn: function () {
              return [
                _c("i", { staticClass: "align-self-center fal fa-globe mr-1" }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.selectedLanguage,
          callback: function ($$v) {
            _vm.selectedLanguage = $$v
          },
          expression: "selectedLanguage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }