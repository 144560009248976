var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "page-footer" }, [
    _c("div", { staticClass: "footer-content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", {
              staticClass: "mb-0 text-muted",
              domProps: { innerHTML: _vm._s(_vm.$t("general.copyright")) },
            }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }