<template>
  <div class="language-selection d-flex">
    <base-single-selection
      v-model="selectedLanguage"
      itemKey="id"
      itemName="name"
      itemSelectedDisplay="name"
      :options="languages"
      placeholder="Select language"
      displayClass="text-uppercase"
      right
    >
      <template #pre-button-content>
        <i class="align-self-center fal fa-globe mr-1"></i>
      </template>
    </base-single-selection>
  </div>
</template>

<script>
import BaseSingleSelection from './BaseSingleSelection.vue'
import { languages } from '@/constants/config'
export default {
  components: {
    BaseSingleSelection
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      languages
    }
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.value
      },
      set(lan) {
        this.$emit('input', lan)
      }
    }
  },
  watch: {
    selectedLanguage: {
      handler(lan) {
        if (lan) {
          this.$emit('changed', lan)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
