import { languages } from '@/constants/config'
import { getCurrentLanguage } from '@/utils'
import LanguageSelection from '@/components/Selections/LanguageSelection.vue'

export const languageMixin = {
  components: {
    LanguageSelection
  },
  data() {
    return {
      languages,
      selectedLanguage: null
    }
  },
  methods: {
    onLanguageChange(lan) {
      this.setLanguage(lan.id)
    },
    setCurrentLanguage() {
      let self = this
      let language = getCurrentLanguage()
      self.selectedLanguage = self.languages.find((lan) => lan.id === language)
    }
  },
  watch: {
    '$i18n.locale'(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path)
      }
    }
  }
}
