var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-single-selection" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "menu-class": "a-shadow-sm",
            "no-caret": "",
            "toggle-class": "p-0",
            variant: "empty",
            right: _vm.right,
          },
          on: {
            hide: function ($event) {
              _vm.shown = false
            },
            toggle: function ($event) {
              _vm.shown = !_vm.shown
            },
          },
        },
        [
          _c("template", { slot: "button-content" }, [
            _vm.loading
              ? _c("div", [
                  _c(
                    "p",
                    { staticClass: "p-0 m-0 align-self-center" },
                    [
                      _c(
                        "b-badge",
                        { attrs: { variant: "light", disabled: "" } },
                        [_vm._v("Loading...")]
                      ),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "d-flex" }, [
                  _vm.badge
                    ? _c(
                        "p",
                        { staticClass: "p-0 m-0 align-self-center" },
                        [
                          _vm._t("pre-button-content"),
                          _vm._v(" "),
                          _c(
                            "b-badge",
                            { attrs: { variant: _vm.badgeVariant } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.selected[_vm.itemName])),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa fa-angle-down align-self-center ml-1",
                              }),
                            ]
                          ),
                        ],
                        2
                      )
                    : _c(
                        "p",
                        { staticClass: "mb-0 align-self-center d-flex" },
                        [
                          _vm._t("pre-button-content"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "align-self-center",
                              class: _vm.displayClass,
                            },
                            [_vm._v(_vm._s(_vm.selectedDisplay))]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "align-self-center fal fa-angle-down text-muted ml-1",
                            class: [_vm.shown ? "shown" : "hidden"],
                          }),
                        ],
                        2
                      ),
                ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.options, function (item) {
            return _c(
              "b-dropdown-item",
              {
                key: `item-${item[_vm.itemKey]}`,
                attrs: { active: _vm.setItemActive(item[_vm.itemKey]) },
                on: {
                  click: function ($event) {
                    return _vm.onItemClick(item)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(item[_vm.itemName]))])]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }