<template>
  <div class="base-single-selection">
    <b-dropdown
      menu-class="a-shadow-sm"
      no-caret
      toggle-class="p-0"
      variant="empty"
      :right="right"
      @hide="shown = false"
      @toggle="shown = !shown"
    >
      <template slot="button-content">
        <div v-if="loading">
          <p class="p-0 m-0 align-self-center">
            <b-badge variant="light" disabled>Loading...</b-badge>
          </p>
        </div>
        <div v-else class="d-flex">
          <p v-if="badge" class="p-0 m-0 align-self-center">
            <slot name="pre-button-content" />
            <b-badge :variant="badgeVariant">
              <span>{{ selected[itemName] }}</span>
              <i class="fa fa-angle-down align-self-center ml-1"></i>
            </b-badge>
          </p>
          <p v-else class="mb-0 align-self-center d-flex">
            <slot name="pre-button-content" />
            <span class="align-self-center" :class="displayClass">{{ selectedDisplay }}</span>
            <i
              :class="[shown ? 'shown' : 'hidden']"
              class="align-self-center fal fa-angle-down text-muted ml-1"
            >
            </i>
          </p>
        </div>
      </template>
      <b-dropdown-item
        v-for="item in options"
        :key="`item-${item[itemKey]}`"
        :active="setItemActive(item[itemKey])"
        @click="onItemClick(item)"
      >
        <span>{{ item[itemName] }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    displayClass: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Your input'
    },
    options: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemName: {
      type: String,
      default: 'name'
    },
    itemSelectedDisplay: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    badge: {
      type: Boolean,
      default: false
    },
    badgeVariant: {
      type: String,
      default: 'outline-primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shown: false
    }
  },
  methods: {
    onItemClick(item) {
      let self = this
      self.$emit('input', item)
    },
    setItemActive(key) {
      let self = this
      return self.selected ? key === self.selected[self.itemKey] : false
    },
    setItemSelectedDisplay(field) {
      let self = this
      return field ? self.selected[self.itemSelectedDisplay] : self.selected[self.itemName]
    }
  },
  computed: {
    selected: {
      get() {
        let self = this
        let item = self.value

        if (item === null) {
          item = {}
          item[self.itemKey] = -1
          item[self.itemName] = self.placeholder
        }

        return item
      }
    },
    selectedDisplay() {
      let self = this
      if (self.selected && self.selected.id === -1) return self.placeholder

      return self.itemSelectedDisplay
        ? self.selected[self.itemSelectedDisplay]
        : self.selected[self.itemName]
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid-tooltip {
  display: block;
}
</style>
